/* You can add global styles to this file, and also import other style files */

/** Global. */
.edit-button {
    width: 200px;
}

.col-date {
    width: 180px;
}

.no-record-message {
    text-align: center;
}

/** Bootstrap overrides. */
.btn {
    font-size: 13px;
}

a.btn {
    border: 1px solid #869099 !important;
}

.btn-light {
    border: 1px solid #ced4da;
    padding: 8px 12px 8px 12px;
}

.form-control {
    width: 400px;
}

.form-control-dropdown {
    width: 210px;
}

button,
input[type="file"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="text"],
select,
textarea {
    border: 1px solid #869099 !important;
}

th {
    border-top: 1px solid #869099 !important;
}

.card-body {
    border-top: 0;
}

.border-bottom {
    border-bottom: 1px solid #869099 !important;
}


.form-control-dropdown,
.form-control-datetime,
input[id=ModifiedDateFormatted].form-control {
    width: 210px;
}

.required:after {
    content: " *";
    position: relative;
    top: 5px;
    color: red;
    font-size: 16pt;
}

body {
    padding-bottom: 40px;
    background-color: #f5f5f5;
    font-size: 0.875rem;
}
